@media(max-width:767px){
    .header_logo.header_thankyou{
        padding: 20px 20px ;
    }
    .main_wrapper{
        max-width: 100%;
    }
    .scanner_customer{
        padding: 0px 20px;
    }
    .scanner_popup{
        box-shadow: 2px -5px 4px rgba(0,0,0,0.16), 0px -2px 2px rgba(0,0,0,0.16);
    }
}