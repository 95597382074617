@font-face {
    font-family: 'Poppins-Bold';
    src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
    font-family: 'Poppins-Light';
    src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
    font-family: 'Poppins-Medium';
    src: url(../fonts/Poppins-Medium.ttf);
}
@font-face {
    font-family: 'Poppins-Regular';
    src: url(../fonts/Poppins-Regular.ttf);
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body,html{
    width: 100%;
    height: 100%;
}
html{
    scroll-behavior: smooth;
}
body{
      font-family: 'Poppins-Regular';
    font-size: 16px;
}
#root{
    height: 100%;
}
a,a:hover{
    text-decoration: none;
}
.main_wrapper{
    max-width: 320px;
    margin: 0 auto;
    height: 100%;    
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    position: relative;
}
.img_max{
    max-width: 100%;
    display: block;
    margin: auto;
}
.header_logo{
    text-align: center;
    padding: 20px 0px;
}
.header_logo .img_max{
    width: 170px;
}
.scanner_body{
    margin-bottom: 40px;
}
.main_heading{
    font-family: 'Poppins-Bold';
    font-size: 18px;
    text-align: center;
    margin-bottom: 25px;
}
.scanner_btns{
    width: 80%;
    margin: 0 auto;
}
button{
    display: block;
    width: 100%;
    padding: 14px 10px;
    border: 1px solid #000;
    margin-bottom: 15px;
    border-radius: 8px;
    cursor: pointer;
}
.yes_btn{
    background-color: #000;
    color: #fff;
}
.no_btn{
    background-color: #fff;
    color: #000;
}
.main_para{
    text-align: center;
    margin-bottom: 20px;
    color: #838383;
}
.thankyou_body{
    border-bottom: 4px solid #000;
}
 .header_thankyou{
    display: flex;
    align-items: center;
    padding: 20px 20px;
}
.header_logo_left span{
    font-size: 12px;    
}
.header_logo_right{
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #838383;
    font-size: 12px;
}
.thank_heading{
    font-size: 26px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 20px;
    font-family: 'Poppins-Bold';
}
.scanner_sign_up{
    margin-top: auto;
    margin-bottom: auto;
}
.scanner_input_div{
    width: 80%;
    margin: 0 auto;
    margin-bottom: 15px;
}
.scanner_input{
    width: 100%;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 13px 12px;
    background-image: url(../img/phone.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 11px;
    background-size: 16px;
    padding-right: 30px;
}
.get_otp{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    gap: 10px;
}
.get_otp_div{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.otp_input{
    width: 100%;
    height: 100%;
    text-align: center;
    border: 0px;
    border: 1px solid #838383;
    color: 838383;
    border-radius: 7px;
}
.scanner_form_div{
    margin-top: auto;
    margin-bottom: auto;
    width: 80%;
    margin: auto;
}
.scanner_form_inner_div{
    width: 100%;
    margin-bottom: 20px;
}
.scannter_select{
    font-size: 16px;
    width: 100%;
    padding: 15px 10px;
    border-radius: 5px;
    padding-right: 30px;
    border: 1px solid #000;
    color: #A09696;
    appearance: none;
    background-image: url(../img/down.png);
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) 14px;
    background-size: 17px;
}
.scanner_form_div .scannter_select{
    background-image: none;
}
.main_inner_div{
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.scanner_popup{
    /* box-shadow: 0 -3px 6px rgba(0,0,0,0.16), 0 2px 6px rgba(0,0,0,0.23); */
    padding: 20px 30px;
    /* position: absolute; */
    bottom: 0;
    z-index: 1;
    background: #fff;
    display: none;
    transition: .5s;
    padding-bottom: 10px;
    transform: translateY(-120px);
}
.header_logo_right .img_max{
    width: 25px;
    cursor: pointer;
}
.header_logo_left .img_max{
    width: 40px;
}
.scanner_popup.show{
   display: block;
}
.scanner_body.scanner_thankyou{
    margin-top: unset;
}
.scanner_connt{
    padding: 0px 20px;
}
.scanner_body.scanner_thankyou .img_max{
    max-width: 80%;
}
.get_otp_btn{
    width: 190px;
}
.scanner_inner_wrap{
    margin-top: auto;
    margin-bottom: auto;
}
.scanner_connt.hide,.scanner_btns.hide{
    display: none;
}
.react-time-picker{
    width: 100%;
}
.react-time-picker__button:enabled{
    display: none;
}
.react-time-picker__wrapper{
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px;
    color: #A09696;
}
.react-time-picker__inputGroup__input{
    color: #757575 !important;
}
.react-time-picker__inputGroup__input{
    min-width: 20px !important;
}